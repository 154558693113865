"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.is = exports.applyPropertyControls = exports.ControlMap = exports.ControlType = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var BOOLEAN = 'boolean';
var ENUM = 'enum';
var NUMBER = 'number';
var STRING = 'string';
var FUSED_NUMBER = 'fusednumber';
var COLOR = 'color';
var IMAGE = 'image';
var FILE = 'file';
var COMPONENT_INSTANCE = 'componentinstance';
var ARRAY = 'array';
var EVENT_HANDLER = 'eventhandler';
var STYLE = 'style';
var OBJECT = 'object';
var ControlType = {
  Boolean: BOOLEAN,
  Enum: ENUM,
  Number: NUMBER,
  String: STRING,
  FusedNumber: FUSED_NUMBER,
  Color: COLOR,
  Image: IMAGE,
  File: FILE,
  ComponentInstance: COMPONENT_INSTANCE,
  Array: ARRAY,
  EventHandler: EVENT_HANDLER,
  Style: STYLE,
  Object: OBJECT
};
exports.ControlType = ControlType;
var ControlMap = Object.entries(ControlType).reduce(function (acc, _ref) {
  var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      val = _ref2[1];

  acc[val] = key;
  return acc;
}, {});
exports.ControlMap = ControlMap;

var applyPropertyControls = function applyPropertyControls(Component, controls) {
  if (Component.propertyControls) {
    Component.propertyControls = _objectSpread({}, Component.propertyControls, {}, controls);
  } else {
    Component.propertyControls = controls;
  }
};

exports.applyPropertyControls = applyPropertyControls;

var is = function is(val) {
  return ControlMap[val];
};

exports.is = is;